import axios, { AxiosInstance } from 'axios'
import AuthToken from '@/auth/auth-token'

class DocumentTag {
  protected readonly documentTag: AxiosInstance;

  constructor () {
    const document = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false
    })
    this.documentTag = document
  }

  public get = async (docTagId: string) => {
    await AuthToken.setAuth(this.documentTag)

    return this.documentTag({
      url: `v1/doc_tag/${docTagId}`,
      method: 'GET'
    })
  }

  public getList = async (offset = 0, limit = 50) => {
    await AuthToken.setAuth(this.documentTag)

    return this.documentTag({
      url: 'v1/doc_tags',
      method: 'GET',
      params: { offset, limit }
    })
  }
}

export default new DocumentTag()
