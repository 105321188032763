
const displayNameConfigs = [
  { type: 'STORE', attr: 'WHOLESALER_STORE_NUMBER' },
  { type: 'VENDOR', attr: 'VENDOR_ID' }
]

function formatPartyType (str) {
  str = str.toLowerCase().replaceAll('_', ' ')
  const parts = str.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1))
  return parts.join(' ')
}

function formatParty (party) {
  let display_name = party.party_name || party.name
  const partyType = party.party_type_constant || party.party_type
  const config = displayNameConfigs.find(c => c.type === partyType)

  if (config && party.attributes) {
    const attrVal = party.attributes[config.attr] || ''
    display_name = `${attrVal} ${display_name}`
  }

  party.name = (partyType === 'STORE') ? `#${display_name}` : display_name
  party.party_type_name = formatPartyType(partyType)
  if (!party.id) party.id = party.party_id
  return party
}

export { displayNameConfigs, formatParty, formatPartyType }