const CONSTANTS = {
  timestamp: 'MM/dd/yyy - h:mm:ss a'
}

const DOCTYPES = [
  {
    category: 'REPORTS',
    type: 'IPRO_BILLING'
  },
  {
    category: 'REPORTS',
    type: 'SRP_ALERT'
  }
]

export default { CONSTANTS, DOCTYPES }
