import { formatParty } from '@/config/party-formatting'
// api
import Search from '@/axios/search'
import { userAccess } from '@/mixins/user-access'
export const partySearch = {
  mixins: [userAccess],
  computed: {
    partySearchTypes () {
      if (this.isVendorUser) return ['VENDOR']
      if (this.isRetailUser) {
        return this.isPromoCoordinator
          ? [...this.retailSearchTypes, 'VENDOR']
          : this.retailSearchTypes
      }
      if (this.isPCEOwner) return ['AD_GROUP', 'STORE', 'OWNER']
      return []
    },
    retailSearchTypes () {
      if (['pricechopper', 'jbg'].includes(this.$auth.tenant)) {
        return ['AD_GROUP', 'STORE']
      }
      return ['AD_GROUP', 'STORE', 'OWNER']
    }
  },
  methods: {
    async queryParties (term, operator = 'and') {
      let results = []
      const { data } = await Search.customerSearch(term, operator)
      if (data?.length > 0) {
        results = this.processPartyData(data)
      }
      return results
    },
    processPartyData (data) {
      return data.flatMap(p => {
        return this.partyFilter(p) ? formatParty(p) : []
      })
    },
    partyFilter (party) {
      const party_id = party.id || party.party_id
      const party_type = party.party_type_constant || party.party_type
      if (this.isNonAdminUser) {
        if (this.partySearchTypes.includes(party_type)) {
          return this.isRetailUser
            ? this.retailPartyFilter(party_type, party_id)
            : this.userRelatedPartyIds.includes(party_id)
        }
        return false
      }
      return party_type !== 'USER'
    },
    retailPartyFilter (party_type, party_id) {
      return party_type === 'VENDOR'
        ? this.isPromoCoordinator
        : this.userRelatedPartyIds.includes(party_id)
    },
    sortByName (a, b, key = 'name') {
      if (a && b) {
        if (a[key] < b[key]) return -1
        else if (a[key] > b[key]) return 1
        else return 0
      }
    },
    sortByPartyType (arr) {
      if (arr.length > 0) {
        const sortedTypes = Array.from(new Set(arr.map(r => r.party_type))).sort()
        return sortedTypes.flatMap(type => {
          const parties = arr.filter(p => p.party_type === type)
          return parties.sort(this.sortByName)
        })
      }
      return arr
    }
  }
}
