import Vue from 'vue'
import VueRouter from 'vue-router'
import docList from '../views/docList.vue'
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Current',
    component: docList,
    meta: {
      title: 'Files',
      requiresAuth: true
    }
  },
  {
    path: '/doc',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "upload" */ '../views/upload.vue'),
    props: route => ({ id: route.query.id }),
    meta: {
      title: 'Edit Files',
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "upload" */ '../views/upload.vue'),
    meta: {
      title: 'Upload Files',
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/retail-manager',
    name: 'Retail Ad Manager',
    component: () => import('@/views/RetailAdManager.vue'),
    meta: {
      title: 'Retail Ad Manager',
      requiresAuth: true
    }
  },
  {
    path: '/retail-items/:ad_id',
    name: 'RetailAdItems',
    component: () => import('@/views/RetailAdItems.vue'),
    meta: {
      title: 'Retail Ad Items',
      requiresAuth: true
    },
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const guardOptions = {
  adminEntities: ['document', 'document_party', 'document_file']
}

router.beforeEach((to, from, next) => {
  to.meta.referrer = from.name
  // Use the route's "meta.title" value to assign the page's title
  if (to.meta.title) document.title = to.meta.title
  // For routes requiring authentication
  if (to.meta.requiresAuth === true) {
    return authGuard(to, from, next, guardOptions)
  }
  return next()
})

export default router
