// Standard/Node Modules
import Vue from 'vue'
import router from './router'
import store from './store'

// Vue Specific Configuration
import App from './App.vue'
import config from './config/document_config'
import dateConfig from './config/date-config'
import moment from 'moment'

// Third Party
import vuetify from './plugins/vuetify'

// Auth0
import { domain, clientId, audience } from './auth/auth0-config.js'
import { Auth0Plugin } from './auth/auth0-plugin'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

const appEnv = process.env.VUE_APP_ENV

if (appEnv !== 'local') {
  Sentry.init({
    Vue: Vue,
    environment: appEnv,
    dsn: 'https://78b699d504ea4337b70ec2e4ea527e47@o461793.ingest.sentry.io/6079644',
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    tracesSampleRate: 1.0
  })
}
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

export const eventbus = new Vue()

Vue.config.productionTip = false

// Instance Properties
Vue.prototype.$config = config.CONSTANTS
Vue.prototype.$dateConfig = dateConfig
Vue.prototype.moment = moment
Vue.prototype.eventbus = eventbus

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
