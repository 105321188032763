import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Party {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.party = base
  }

  async getUserProfile (email, include_stores = false, limit_stores = false, owners_from_children = false, extended_access = false) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/user_profile',
      method: 'GET',
      params: { email, include_stores, limit_stores, owners_from_children, extended_access }
    })
  }

  async getStoresByAdGroup (partyId) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: `/all_stores_for_adgroup/${partyId}`,
      method: 'GET'
    })
  }

  async getAllStoresForTenant () {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: `/all_stores_for_tenant`,
      method: 'GET'
    })
  }
}

export default new Party()