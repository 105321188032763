export const paging = {
  data () {
    return {
      from: 0,
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 200]
    }
  },
  watch: {
    search: {
      handler () {
        this.page = 1
        this.tableChanged()
      }
    },
    pageSize: {
      handler () {
        if (this.page > 1) {
          return this.page = 1
        }
        this.tableChanged()
      }
    },
    page: {
      handler(newValue) {
        this.loading = true
        if (newValue > 1) {
          this.from = (newValue * this.pageSize) - this.pageSize
          this.tableChanged()
        } else {
          this.from = 0
          this.tableChanged()
        }
        const table = this.$refs['my-table']
        if (table) {
          const wrapper = table.$el.querySelector('div.v-data-table__wrapper')

          this.$vuetify.goTo(table)
          this.$vuetify.goTo(table, { container: wrapper })
        }
      }
    }
  },
  methods: {
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
    },
    next () {
      this.page = this.page + 1
    },
    tableChange (searchFunc) {
      return function () {
        searchFunc()
      }
    }
  }
}
