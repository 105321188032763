import { getInstance } from './auth0-plugin'

const maintenance = JSON.parse(process.env.VUE_APP_MAINTENANCE_STATUS)

export const authGuard = (to, from, next, guardOptions) => {
  const authService = getInstance()

  const { adminEntities } = guardOptions
  const adminScopes = authService.buildScopes(adminEntities)

  const guardAction = () => {
    if (authService.isAuthenticated) {
      const isAdminUser = authService.checkScopes(adminScopes)
      if (authService.tenantTitle) {
        document.title = `${authService.tenantTitle} | ${to.meta?.title}`
      }
      if (maintenance) {
        if (to.path !== '/') return next('/')
        else return next()
      }
      if (to.meta.adminOnly && !isAdminUser) {
        return next('/')
      }
      return next()
    }
    const options = { appState: { targetUrl: to.fullPath } }
    authService.loginWithRedirect({ ...options })
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction()
  }

  authService.$watch('isLoading', (isLoading) => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
