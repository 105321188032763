import axios, { AxiosInstance, AxiosResponse } from 'axios'
import AuthToken from '@/auth/auth-token'

class File {
  protected readonly file: AxiosInstance;
  headers: Record<string, string>;

  constructor () {
    this.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
    const file = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: { ...this.headers }
    })

    this.file = file
  }

  handleSuccess (response: AxiosResponse) {
    return response
  }

  public put = async (docId: string, data: FormData) => {
    await AuthToken.setAuth(this.file)

    return this.file({
      url: `v1/doc/${docId}/file`,
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data'
      },
      data: data
    })
  }

  public get = async (docId: string) => {
    await AuthToken.setAuth(this.file)

    return this.file({
      url: `v1/doc/${docId}/file`,
      method: 'GET'
    })
  }
}

export default new File()
