<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete
      style="margin-top: 26px"
      :value="selectedParties"
      :items="parties"
      item-text="name"
      item-value="id"
      label="Parties"
      :loading="loadingParties"
      :search-input.sync="partySearch"
      :autofocus="!isNonAdminUser && selectedParties.length === 0"
      background-color="input"
      :prepend-inner-icon="isNonAdminUser ? null : 'mdi-magnify'"
      :placeholder="isNonAdminUser ? null : 'Start typing to search'"
      hide-no-data
      multiple
      cache-items
      return-object
      small-chips
      dense
      outlined
      @change="$emit('update:selectedParties', $event)">
      <template v-slot:selection="{ attrs, selected, select, index, item }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          small
          @click="select"
          @click:close="$emit('remove', index)">
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
        </v-chip>
      </template>
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          v-bind="attrs"
          color="primary"
          v-on="on">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.party_type_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>
<script lang="js">
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { partySearch } from '@/mixins/party-search'
// third-party
import { debounce } from 'lodash'
export default {
  data () {
    return {
      parties: [],
      partySearch: '',
      loadingParties: false
    }
  },
  name: 'PartySelect',
  mixins: [displayAlert, userAccess, partySearch],
  props: {
    selectedParties: Array
  },
  watch: {
    partySearch: {
      handler: debounce(function(newValue) {
        if (this.partySearchEnabled && newValue) {
          this.searchParties()
        }
      }, 500)
    },
    selectedParties: {
      handler (newValue) {
        if (newValue?.length > 0) {
          this.initParties()
        }
      },
      deep: true
    },
    userRelatedPartyIds: {
      handler (newValue) {
        if (newValue?.length > 0 && this.isNonAdminUser) {
          this.parties.push(...this.userDropdownParties)
        }
      },
      deep: true
    }
  },
  created () {
    if (this.selectedParties.length > 0) {
      this.initParties()
    }
    if (this.isNonAdminUser && this.userRelatedParties.length > 0) {
      this.parties.push(...this.userDropdownParties)
    }
  },
  computed: {
    userDropdownParties () {
      return this.userRelatedParties.filter(this.partyFilter)
    },
    partySearchEnabled () {
      return this.isPromoCoordinator || !this.isNonAdminUser
    }
  },
  methods: {
    initParties () {
      this.parties = [...this.selectedParties]
    },
    async searchParties () {
      if (this.isNonAdminUser && this.userRelatedPartyIds.length === 0) return
      if (this.partySearch) this.loadingParties = true
      let parties = []
      const { term, operator } = this.buildSearchParams()
      try {
        const results = await this.queryParties(term, operator)
        if (results?.length > 0) {
          parties = this.sortByPartyType(results)
        }
        this.parties = [...this.parties, ...parties]
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingParties = false
      }
    },
    buildSearchParams () {
      let term = ''
      let operator = 'and'
      if (this.partySearch) {
        term = this.partySearch
      } else if (this.isNonAdminUser) {
        term = this.userSearchPartyIds.join(' ')
        operator = 'or'
      }
      return { term, operator }
    }
  }
}
</script>