import axios from 'axios'

const host = window.location.hostname
let apiKey = process.env.VUE_APP_IPRO_API_KEY
if (host.includes('awg')) {
  apiKey = process.env.VUE_APP_AWG_API_KEY
}
if (host.includes('spn')) {
  apiKey = process.env.VUE_APP_SPN_API_KEY
}
if (host.includes("cosentino")) {
  apiKey = process.env.VUE_APP_COSENTINO_API_KEY;
}
if (host.includes("arg")) {
  apiKey = process.env.VUE_APP_ARG_API_KEY;
}
if (host.includes("pce")) {
  apiKey = process.env.VUE_APP_PCE_API_KEY;
}
if (host.includes("jbg")) {
  apiKey = process.env.VUE_APP_JBG_API_KEY;
}

class Search {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_SEARCH_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'x-api-key': apiKey
      }
    })
    this.search = base
  }

  async customerSearch (term, operator = 'or') {
    return this.search({
      url: '/search',
      method: 'GET',
      params: {
        context: 'customer',
        term,
        operator
      }
    })
  }
}

export default new Search()